import { render, staticRenderFns } from "./newsItem2.vue?vue&type=template&id=dd8407c2&scoped=true&"
import script from "./newsItem2.vue?vue&type=script&lang=js&"
export * from "./newsItem2.vue?vue&type=script&lang=js&"
import style0 from "./newsItem2.vue?vue&type=style&index=0&id=dd8407c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd8407c2",
  null
  
)

export default component.exports