<template>
  <div class="wrap">
    <div class="nav">
      <div class="navList">
        <div @click="jump('1')">
          <span>首页</span>
          <span>Home</span>
        </div>
        <div @click="jump('2')">
          <span>服务案例</span>
          <span>Case</span>
        </div>
        <div @click="jump('3')" class="cur">
          <span>新闻资讯</span>
          <span>News</span>
        </div>
        <div @click="jump('4')">
          <span>业务伙伴</span>
          <span>Cooperation</span>
        </div>
        <div @click="jump('5')">
          <span>关于我们</span>
          <span>About</span>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="title">
        <p>建筑设计中玻璃幕墙的作用你是否都知道？</p>
        <p>发布日期：2021-03-21</p>
      </div>
      <div class="text">
        <div>
          现在城市化建设进程的加快，促使高层建筑不断地增加，与此同时存在高层建筑采光现象，而且伴随着地球的逐渐变暖，这种气候自然环境下的通风透气也变成了当今民众对建筑物的一项主要的需求，所以调节建筑物采光度，提高建筑物通风性的玻璃幕墙变成了现代建筑设计中的重要环节，也是当今不少高层商用及民用建筑设计通常选用的要素。而且因玻璃幕墙具有材料多样，形式各异，适用于各种类型气候、各种类型人的不一样生活的需求，需加强对建筑设计中玻璃幕墙实现合理运用，才可以展现出玻璃幕墙实际意义，所以其在建筑设计中的运用非常主要。
        </div>
        <img src="../../public/imgs/news/text3.png" alt="" />
        <div>建筑设计中玻璃幕墙运用的作用主要有：</div>
        <div>（1）调节建筑物采光度</div>
        <div>
          建筑物采光度是影响着住户总体居住满意度的主要因素，运用在民用房屋设计阶段中，可调节房屋的总体视觉感，让阴暗的局部变得明亮，让阳光耀眼的局部变得柔和，可使住户倍感舒适，充满活力。运用在商业建筑设计中，可改变建筑物总体的氛围，让人觉得明亮舒适的体验。所以在设计过程之中，设计师理应意识到玻璃幕墙的这一主要运用实际意义，不论是民用还是商用建筑设计之中，都理应积极运用玻璃幕墙要素，提高建筑物通透感。但應当注意的是玻璃类型的选用。建筑物的阳面与阴面，体验是完全不一样的，阴面普遍光照缺乏，阳面则容易有光照过强耀眼的现象，所以在设计过程中，理应注重运用玻璃幕墙调节建筑物采光。针对建筑物的阴面，可选用透明玻璃的幕墙，集中采光。针对建筑物的阳面，则可选用茶色玻璃，缓解视觉效果。在新的时代特征下，更有先进的变色玻璃，既可用以阴面也可用以阳面，由于这种的玻璃是依据光照辐射程度自动改变颜色的，是相当适用玻璃幕墙的玻璃材料。
        </div>

        <div>（2）满足建筑物通风的需求。</div>
        <div>
          通风性对于建筑物总体自然环境具有很大影响。通风不当的建筑物会让人觉得不舒适、不通透的感觉，尤其是在逐渐温暖化的自然环境下，通风的玻璃设计更是能在生理与视觉上给人清爽的体验。为确保玻璃幕墙通风实际意义的体现，设计师可针对局部玻璃幕墙采用可开闭式的设计，在阴雨天气可关闭起来变成封闭式空间，晴天时则可打开，让外部的空气与风能更好的渗透进来，这种可塑造出更为健康、清新的建筑物空间，尤其是商业建筑在设计过程中，更可运用这种的玻璃幕墙塑造出露天活动区，为各种类型活动的举办提供更为开放性的空间。
        </div>
        <div>（3）扩展视野。</div>
        <div>
          宽阔的视野不论是对于商业空间还是民用建筑，都是一项主要的需求，由此可见的空间越发不足、空间的约束感就会越强，人们的满意度就会越低。为此在设计过程当中，设计师理应灵活运用玻璃幕墙的通透性特征，扩展建筑空间的视野。在民用建筑设计当中融入玻璃幕墙，即使是小户型，也可以让住户在居住后感到房屋不约束、不拥挤，通过玻璃幕墙观察外界空间，也可以有更加宽阔的视野。
        </div>
        <div>
          目前商业空间与民用建筑设计过程中的玻璃幕墙是其常见的要素，备受相关从业人员的追捧，主要是与其通透性、反射性等特征有着较大的关系，合理利用可以让建筑观感更加清爽，透气性更强，并且可以增强建筑整体采光性。但目前相关专业人员还理应进一步提高玻璃幕墙材料的刚度和使用寿命，这才可以发挥玻璃幕墙的作用，从而增强建筑整体质量。
        </div>
        <img src="../../public/imgs/news/text4.png" alt="" />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/Footer";
export default {
  name: "index",
  components: {
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    jump(index) {
      if (index == 1) {
        this.$router.push({
          path: "/",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/case",
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/news",
        });
      }
      if (index == 4) {
        this.$router.push({
          path: "/partner",
        });
      }
      if (index == 5) {
        this.$router.push({
          path: "/me",
        });
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wrap {
  height: 100vh;
  width: 1920px;
  margin: 0 auto;
}
.cur {
  color: #028ccd !important;
}
.nav .navList {
  position: absolute;
  top: 25px;
  right: 300px;
  height: 50px;
}
/* 导航 */
.nav {
  width: 100%;
  height: 100px;
  background: #fff url("../../public/imgs/indexNew/logo.png") no-repeat 76px
    center;
  overflow: hidden;
  cursor: pointer;
  position: fixed;
  top: 0;
  z-index: 8888;
}
.navList span {
  display: block;
}
.navList div {
  text-align: center;
  float: left;
  line-height: 50px;
  height: 70px;
  width: 70px;
  margin-right: 45px;
  font-size: 16px;
  color: #666666;
  position: relative;
  top: 0;
  transition: top 0.5s;
}
.navList div span:nth-of-type(1) {
  opacity: 1;
  transition: all 0.5s;
}
.navList div span:nth-of-type(2) {
  opacity: 0;
  transition: all 0.5s;
}
.navList div:hover {
  top: -50px;
}
.navList div:hover span:nth-of-type(1) {
  opacity: 0;
}
.navList div:hover span:nth-of-type(2) {
  opacity: 1;
}
.main {
  width: 1920px;
  margin: 100px auto 50px;
}
.title {
  background: #eee;
  height: 150px;
  overflow: hidden;
}
.title p {
  width: 1200px;
  margin: 0 auto;
  text-align: left;
}
.title p:nth-of-type(1) {
  font-size: 30px;
  color: #1f1f1f;
  margin-top: 46px;
}
.title p:nth-of-type(2) {
  line-height: 34px;
  font-size: 12px;
  color: #a0a0a0;
}

.text {
  width: 1200px;
  margin: 0 auto;
}
.text div {
  font-size: 14px;
  color: #5b5b5b;
  line-height: 24px;
  margin-top: 70px;
}
.text img {
  width: 100%;
}
</style>
